import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import PersonImage from "../images/person.png"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Myself Kalaivanan and I am a professional full-stack developer from India with pieces of knowledges on React, Nestjs, jQuery, Laravel, and Codeigniter and many more"
      />
      <title>Kalaivanan - Professional full-stack engineer</title>
    </Helmet>
    <div className="home page">
      <div className="banner d-none d-lg-block">
        <img alt="" className="img-fluid" src={PersonImage} />
      </div>
      <div className="row">
        <div className="col col-sm-12 col-md-12 col-lg-6">
          <div className="title">
            <span className="title--sup">Hello I'm</span>
            <h1 className="title--header">Kalaivanan</h1>
            <h2 className="title--sub">Professional full-stack engineer</h2>
            <span className="break-line" />
          </div>
          <p className="mt-3">
            I started coding my first programming in 2013 with hpage.com and
            later with PHP, HTML, CSS and bit jQuery. Since 2017, started
            working in modern JavaScript, HTML, CSS and Web Accessibility.
            Currently in{" "}
            <span role="img" aria-label="Growing Heart">
              &#128151;
            </span>{" "}
            with React.
          </p>
          <Link className="btn btn-primary mt-3 mr-3" to="/about-me">
            Read more
          </Link>
          <Link className="btn btn-outline-primary mt-3" to="/blog">
            My Blog
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
